<template>
  <div v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" v-if="requisition.hub_transfer_chalan_form_picture">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="requisition.hub_transfer_chalan_form_picture_url" target="_blank" download>
                <img :src="requisition.hub_transfer_chalan_form_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
                <div class="chalan-form-pictre-footer">Figure: Hub Received Products Chalan Form</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-bordered" id="requisition_item_list">
              <thead>
              <tr>
                <th>#</th>
                <th>Product</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>Accepted Qty</th>
                <th>Dispute Type</th>
                <th>Status</th>
                <th>Transfer Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(transfer, index) in transfers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ transfer.product.name }}</td>
                <td>{{ transfer.product.unit }}</td>
                <td>{{ transfer.transfer_amount }}</td>
                <td><span v-if="transfer.transfer_status != 'Pending'">{{ acceptedAmount(transfer.transfer_amount, transfer.dispute_amount)}}</span></td>
                <td><span v-if="transfer.transfer_status != 'Pending'">{{ transfer.dispute_type ? transfer.dispute_type.dispute_type_desc : 'N/A' }}</span></td>
                <td>
                  <a-tag color="#f50" v-if="transfer.transfer_status == 'Pending'">Pending</a-tag>
                  <a-tag color="#f50" v-if="transfer.transfer_status == 'Rejected'">Rejected</a-tag>
                  <a-tag color="#87d068" v-if="transfer.transfer_status == 'Accepted'">Full Accepted</a-tag>
                  <a-tag color="#E0A800" v-if="transfer.transfer_status == 'Partial'">Partially Accepted</a-tag>
                </td>
                <td>{{ customDate(transfer.created_at) }}</td>
                <td>
                  <a v-if="transfer.transfer_status == 'Pending'" href="javascript: void(0);" v-b-modal.transfer-full-accept-modal @click="$bvModal.show('transfer-full-accept-modal'), fullAccept(transfer)" class="btn btn-sm btn-success mr-1">Accept</a>
                  <a v-if="transfer.transfer_status == 'Pending'"  href="javascript: void(0);" v-b-modal.transfer-partial-accept-modal @click="$bvModal.show('transfer-partial-accept-modal'), partialAccept(transfer)" class="btn btn-sm btn-warning">Partial Accept</a>
                  <a v-if="transfer.transfer_status == 'Partial'" :href="transfer.hub_transfer_picture" target="_blank" download>
                    <img :src="transfer.hub_transfer_picture" alt="" class="rounded d-block" width="40" height="40"/>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="transfer_accept_pending_count === 0">
              <div class="row mt-3 mb-3"  v-if="requisition.requisition_status.includes('REQUISITION_TRANSFER_RECEIVED_PENDING') && !requisition.requisition_status.includes('REQUISITION_TRANSFER_RECEIVED')">
                <div class="col-md-12">
                  <p class="float-right" style="color: red">* If all products accepted / partial accepted, click the button
                    <br>
                    <a  href="javascript: void(0);" v-b-modal.item-received-with-chalan-form-modal @click="$bvModal.show('item-received-with-chalan-form-modal')" class="btn btn-success waves-effect waves-light float-right"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Received All</a>
                  </p>
                </div>
              </div>
            </div>
            <a-skeleton active :loading="loading"></a-skeleton>
            <h5 class="text-center text-secondary" v-if="transfers.length === 0">
              <Empty :image="simpleImage"></Empty>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <full-accept :transfer="transfer" @getTransferRequisitionItems="getTransferRequisitionItems"></full-accept>
    <partial-accept :transfer="transfer"  :dispute_types="dispute_types" @getTransferRequisitionItems="getTransferRequisitionItems"></partial-accept>
    <item-received-with-chalan-form :requisition="requisition" @getTransferRequisitionItems="getTransferRequisitionItems"></item-received-with-chalan-form>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import fullAccept from '@/views/requisition/transfer/modal/fullAccept'
import partialAccept from '@/views/requisition/transfer/modal/partialAccept'
import itemReceivedWithChalanForm from '@/views/requisition/transfer/modal/itemReceivedWithChalanForm'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'List',
  components: { Empty, fullAccept, partialAccept, requisitionHeaderDetail, requisitionHeaderStatus, itemReceivedWithChalanForm, requisitionHubDetail },
  data() {
    return {
      transfers: {},
      transfer: {},
      requisition: {},
      dispute_types: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      transfer_accept_pending_count: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getTransferRequisitionItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getTransferRequisitionItems() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/requisition-pending-transfer/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.transfers = data.transfers
            this.requisition = data.requisition
            this.current = data.max_item_requisition_status
            this.dispute_types = data.dispute_types
            this.transfer_accept_pending_count = data.transfer_accept_pending_count
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    fullAccept(transfer) {
      this.transfer = {}
      this.transfer = transfer
    },
    partialAccept(transfer) {
      this.transfer = {}
      this.transfer = transfer
    },
    acceptedAmount(transferAmount, DisputeAmount) {
      return (parseFloat(transferAmount) - parseFloat(DisputeAmount))
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
</style>
