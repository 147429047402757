<template>
  <div>
    <div class="mb-5">
      <b-modal id="item-received-with-chalan-form-modal" title="All Product Received With Chalan Form" size="lg" hide-footer @hidden="refreshData">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded" v-if="Object.keys(requisition).length !== 0">
          <form action="" enctype="multipart/form-data" files="true" role="form">
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hub_transfer_chalan_form_picture" class="col-form-label col-form-label-sm">Transfer Chalan Form Picture <span class="custom-required">*</span></label>
                <input type="file" ref="hubTransferChalanFormPicture" v-validate="'required|image'" id="hub_transfer_chalan_form_picture" name="hub_transfer_chalan_form_picture" @change="getGeneratorReading()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Transfer chalan form picture must be image</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="uploadPicture">Upload & Received</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('item-received-with-chalan-form-modal')">Cancel</a-button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'itemReceivedWithChalanForm',
  props: ['requisition'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      hub_transfer_chalan_form_picture: '',
    }
  },
  mounted() { },
  methods: {
    uploadPicture() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('requisitionId', this.requisition.id)
          formData.append('hub_transfer_chalan_form_picture', this.hub_transfer_chalan_form_picture)
          apiClient.post('api/hub/requisition-received-all', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.$bvModal.hide('item-received-with-chalan-form-modal')
            this.loader = false
            if (response.data.error === false) {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('getTransferRequisitionItems')
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    refreshData() {
      this.hub_transfer_chalan_form_picture = ''
    },
    getGeneratorReading() {
      this.hub_transfer_chalan_form_picture = this.$refs.hubTransferChalanFormPicture.files[0]
      if (this.hub_transfer_chalan_form_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.hub_transfer_chalan_form_picture = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
