<template>
  <div>
    <div class="mb-5">
      <b-modal id="transfer-partial-accept-modal" title="Partial Accept" size="lg" hide-footer @hidden="refreshData">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded" v-if="Object.keys(transfer).length !== 0">
          <form action="" enctype="multipart/form-data" files="true" role="form">
            <table class="table table-sm table-bordered">
              <tr>
                <th>Product</th>
                <td>{{ transfer.product.name }}</td>
              </tr>
              <tr>
                <th>Transfer Amount</th>
                <td>{{ transfer.transfer_amount }}</td>
              </tr>
            </table>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="accepted_amount" class="col-form-label col-form-label-sm">Partial Amount(After missing & damage remaining qty) <span class="custom-required">*</span></label>
                <input type="number" v-model="transfer.partial_accepted_amount" v-validate="{ required: true, max: 5, regex: /^[0-9]*$/ }" @change="partialAcceptAmountCheck($event.target.value, transfer.transfer_amount)" id="accepted_amount" name="accepted_amount" class="form-control form-control-sm" placeholder="Enter accept amount"/>
                <div class="invalid-feedback">Partial accept is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="accepted_amount" class="col-form-label col-form-label-sm">Dispute Type <span class="custom-required">*</span></label>
                <select v-model="transfer.dispute_type_id" v-validate="'required'" id="dispute_type_id" name="dispute_type_id" class="form-control form-control-sm">
                  <option selected :value="''">Select a dispute type</option>
                  <option v-for="(dispute_type, index) in dispute_types" :key="index" :value="dispute_type.id">{{ dispute_type.dispute_type_desc }}</option>
                </select>
                <div class="invalid-feedback">Dispute type is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hub_comment" class="col-form-label col-form-label-sm">Comment</label>
                <textarea v-model="transfer.hub_comment" name="hub_comment" id="hub_comment" rows="2" class="form-control form-control-sm" placeholder="Enter comment.."></textarea>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hub_transfer_picture" class="col-form-label col-form-label-sm">Transfer Partial Accept Picture</label>
                <input type="file" ref="hubTransferPicture" v-validate="'image'" id="hub_transfer_picture" name="hub_transfer_picture" @change="getGeneratorReading()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Transfer partial accept picture must be image</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="partialAccept">Accept</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('transfer-partial-accept-modal')">Cancel</a-button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'partialAccept',
  props: ['transfer', 'dispute_types'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      hub_transfer_picture: '',
    }
  },
  mounted() { },
  methods: {
    partialAccept() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('transfer', JSON.stringify(this.transfer))
          formData.append('hub_transfer_picture', this.hub_transfer_picture)
          apiClient.post('api/hub/transfer/partial-accept', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.$bvModal.hide('transfer-partial-accept-modal')
            this.loader = false
            if (response.data.error === false) {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('getTransferRequisitionItems')
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    partialAcceptAmountCheck($event, availableQuantity) {
      const totalAvailableQuantity = availableQuantity
      const acceptAmount = $event
      if (parseFloat(acceptAmount) > parseFloat(totalAvailableQuantity)) {
        this.$notification.error({
          message: 'Partial quantity amount is crossing total transfer amount',
        })
        this.$bvModal.hide('transfer-partial-accept-modal')
      }
    },
    refreshData() {
      this.transfer.partial_accepted_amount = ''
      this.transfer.hub_comment = ''
      this.transfer.dispute_type_id = ''
      this.hub_transfer_picture = ''
    },
    getGeneratorReading() {
      this.hub_transfer_picture = this.$refs.hubTransferPicture.files[0]
      if (this.hub_transfer_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.hub_transfer_picture = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
